/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css'

import './src/styles/global.scss'
console.log('version: ', process.env.APP_VERSION)
